import { FC, useEffect, useRef, useState } from 'react'
import styles from './WallOfLove.module.scss'
import { Img } from 'components/basic/Img'
import { VideoItem } from './wallOfLoveIterfaces'
import { useMouseFaceTracking } from 'components/_hooks/useMouseFaceTracking'
import cx from 'classnames'
import { Type } from 'components/Type'

export const VideoCard: FC<Omit<VideoItem, 'categories'> & { id: string }> = (props) => {
	const [play, setPlay] = useState(props.autoplay)
	const [muted, setMuted] = useState(true)
	const videoRef = useRef<HTMLVideoElement>()
	const [containerRef, handleMouseMove, handleMouseLeave] = useMouseFaceTracking<HTMLDivElement>()
	const pointerDownTimer = useRef<number>(0)

	const handleSetPlay = (value: boolean) => {
		if (!props.autoplay) {
			setPlay(value)
		}
	}

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.defaultMuted = true
			if (play) {
				videoRef.current.play()
				videoRef.current.currentTime = 0
			} else {
				videoRef.current.pause()
				setMuted(true)
			}
		}
	}, [play])

	return (
		<div
			className={cx(styles.container, 'wall-of-love-video')}
			id={props.id}
			key={props.name}
			ref={containerRef}
			onPointerMove={handleMouseMove}
			onPointerEnter={(e) => {
				if (e.pointerType !== 'mouse') return
				handleSetPlay(true)
			}}
			onPointerLeave={(e) => {
				if (e.pointerType !== 'mouse') return
				handleSetPlay(false)
				handleMouseLeave()
			}}
		>
			<button
				className={styles.video_button}
				style={{
					visibility: props.autoplay ? 'hidden' : 'visible',
				}}
				onClick={() => setMuted((prev) => !prev)}
			>
				{
					<svg
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						{muted ? (
							<>
								<path
									d="M4 15H5.586L10.293 19.707C10.923 20.337 12 19.891 12 19V5.00001C12 4.10901 10.923 3.66301 10.293 4.29301L5.586 9.00001H4C3.73478 9.00001 3.48043 9.10536 3.29289 9.2929C3.10536 9.48044 3 9.73479 3 10V14C3 14.2652 3.10536 14.5196 3.29289 14.7071C3.48043 14.8946 3.73478 15 4 15Z"
									stroke={'white'}
									strokeWidth="1.25"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M19 12L21 14M17 14L19 12L17 14ZM19 12L21 10L19 12ZM19 12L17 10L19 12Z"
									stroke={'white'}
									strokeWidth="1.25"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</>
						) : (
							<path
								d="M15.536 8.46401C16.0004 8.92832 16.3688 9.47957 16.6202 10.0863C16.8716 10.693 17.0009 11.3433 17.0009 12C17.0009 12.6567 16.8716 13.307 16.6202 13.9137C16.3688 14.5204 16.0004 15.0717 15.536 15.536M18.364 5.63601C19.1997 6.47174 19.8627 7.46389 20.315 8.55583C20.7673 9.64777 21.0001 10.8181 21.0001 12C21.0001 13.1819 20.7673 14.3522 20.315 15.4442C19.8627 16.5361 19.1997 17.5283 18.364 18.364M5.586 15H4C3.73478 15 3.48043 14.8946 3.29289 14.7071C3.10536 14.5196 3 14.2652 3 14V10C3 9.73479 3.10536 9.48044 3.29289 9.2929C3.48043 9.10536 3.73478 9.00001 4 9.00001H5.586L10.293 4.29301C10.923 3.66301 12 4.10901 12 5.00001V19C12 19.891 10.923 20.337 10.293 19.707L5.586 15Z"
								stroke="white"
								strokeWidth="1.25"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						)}
					</svg>
				}
			</button>

			<video
				src={props.video}
				className={styles.video}
				onEnded={() => handleSetPlay(false)}
				controls={false}
				ref={videoRef}
				loop={props.autoplay}
				preload={'none'}
				muted={!!props.autoplay || !!muted ? true : false}
				playsInline
				disableRemotePlayback
				disablePictureInPicture
				autoPlay={props.autoplay}
				style={{ display: play ? 'block' : 'none' }}
				onPointerDown={(e) => {
					if (e.pointerType === 'mouse') return
					pointerDownTimer.current = new Date().getTime()
				}}
				onPointerUp={(e) => {
					if (e.pointerType === 'mouse') return
					const pointerUpTimer = new Date().getTime()
					if (pointerUpTimer - pointerDownTimer.current < 200) {
						handleSetPlay(false)
					}
				}}
			/>

			<div className={styles.bg}>
				<div className={styles.shade} />
				<div className={styles.play_button}>
					<svg
						viewBox="0 0 50 60"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14.8916 1.61031L45.5078 22.0275C46.8194 22.9021 47.8948 24.0965 48.6386 25.4864C49.3824 26.8763 49.7715 28.4284 49.7715 30.0048C49.7715 31.5812 49.3824 33.1333 48.6386 34.5232C47.8948 35.9131 46.8194 37.0979 45.5078 37.9725L14.8916 58.3897C13.4493 59.3519 11.7729 59.9046 10.0412 59.9887C8.30947 60.0728 6.58739 59.6852 5.05869 58.8673C3.52998 58.0494 2.252 56.8318 1.36109 55.3444C0.470179 53.8571 -0.000237311 52.1558 2.21654e-05 50.422V9.59716C-0.00371101 7.86176 0.464188 6.15797 1.3537 4.66789C2.24322 3.1778 3.52091 1.95742 5.05023 1.13719C6.57955 0.316964 8.30301 -0.0722825 10.0364 0.0110494C11.7698 0.0943813 13.448 0.647165 14.8916 1.61031Z"
							fill="#F2F4F6"
						/>
					</svg>
				</div>
				<Img
					src={props.thumbnail}
					alt={props.name}
					objectFit={'cover'}
					dprHeight={600}
				/>
			</div>

			<div
				className={cx(styles.article_content, 'article_content')}
				onPointerDown={(e) => {
					if (e.pointerType === 'mouse') return
					pointerDownTimer.current = new Date().getTime()
				}}
				onPointerUp={(e) => {
					if (e.pointerType === 'mouse') return
					const pointerUpTimer = new Date().getTime()
					if (pointerUpTimer - pointerDownTimer.current < 200) {
						handleSetPlay(true)
					}
				}}
			>
				<Type.Headchapter as={'h2'}>{props.name}</Type.Headchapter>
				<Type.Body2>{props.title}</Type.Body2>
			</div>
		</div>
	)
}
