/* eslint-disable @next/next/no-img-element */
import { FC } from 'react'
import { CardItem } from './wallOfLoveIterfaces'
import styles from './WallOfLove.module.scss'
import { Img } from 'components/basic/Img'
import { useMouseFaceTracking } from 'components/_hooks/useMouseFaceTracking'

export const BasicCard: FC<CardItem> = (props) => {
	const [containerRef, handleMouseMove, handleMouseLeave] = useMouseFaceTracking<HTMLDivElement>()

	return (
		<div
			className={`${styles.basic_card} ${props.className} ${styles.container}`}
			ref={containerRef}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
		>
			<div>
				<Img
					src={props.imageSrc}
					alt={props.title}
				/>
				<strong>{props.title}</strong>
			</div>
			<p>{props.subtitle}</p>
		</div>
	)
}
