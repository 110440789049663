import { MutableRefObject, useRef } from 'react'
import { clamp } from 'utils'

export const useMouseFaceTracking = <T extends HTMLElement>(xSensitivity = 64, ySensitivity = 64, maxXRotation = 2.5, maxYRotation = 2.5): [MutableRefObject<T>, (e: any) => void, () => void] => {
	const containerRef = useRef<T>(null)

	const handlePointerMove = (e: any) => {
		if (e.pointerType === 'touch') return
		const container = containerRef.current

		if (container) {
			const rect = container.getBoundingClientRect()
			const x = e.clientX - (rect.left + rect.width / 2)
			const y = e.clientY - (rect.top + rect.height / 2)
			const rotationX = -y / ySensitivity
			const rotationY = x / xSensitivity
			container.style.transform = `perspective(600px) rotateX(${clamp(rotationX, -maxXRotation, maxXRotation)}deg) rotateY(${clamp(rotationY, -maxYRotation, maxYRotation)}deg)`
			container.style.transition = `unset`
		}
	}

	const handlePointerLeave = () => {
		const container = containerRef.current
		if (container) {
			container.style.transform = `perspective(600px) rotateX(0deg) rotateY(0deg)`
			container.style.transition = `transform 0.5s ease`
		}
	}

	return [containerRef, handlePointerMove, handlePointerLeave]
}
